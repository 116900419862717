// import type { PageProps } from 'gatsby'
// import React from 'react'
// import Text, { type TextProps } from 'components/Text'
// import EnterpriseFitnessLanding from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
// import enterprise from 'libs/enterprise'
// import { ReactComponent as LogosSvg } from './images/logos.svg'
// type Props = PageProps
// export default function EnterpriseUrchin(props: Props) {
//   const textYellowProps: Partial<TextProps> = {
//     color: 'yellow',
//     weight: 'semibold',
//   }
//   return (
//     <EnterpriseFitnessLanding
//       {...props}
//       heroProps={{
//         subtext: (
//           <Text element="p" flush>
//             Meet Bold—your <Text {...textYellowProps}>at home</Text> online exercise program. Bold
//             is offered to UCLA Health Medicare Advantage Principal HMO Plan members{' '}
//             <Text {...textYellowProps}>no cost</Text>.
//           </Text>
//         ),
//       }}
//       logos={LogosSvg}
//       partner={enterprise.URCHIN_KEY}
//       className="EnterpriseUrchin"
//     />
//   )
// }
import type { PageProps } from 'gatsby'
import React from 'react'
import EnterpriseFitnessLanding from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = PageProps

export default function EnterpriseUrchin(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      logos={LogosSvg}
      partner={enterprise.URCHIN_KEY}
      className="EnterpriseUrchin"
    />
  )
}
